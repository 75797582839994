











import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { reportsRouteShow } from '@/views/reports/routes';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'Reports',

  components: {
    LayoutPage,
  },

  data() {
    return {
      reportsRouteShow,
    };
  },
});
