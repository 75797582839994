



































import BaseLoader from '@/components/base/BaseLoader.vue';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'LayoutSection',

  components: {
    BaseLoader,
  },

  props: {
    empty: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classList() {
      return {
        'section--empty': this.empty,
      };
    },
  },
});
