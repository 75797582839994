

















































































import BaseButtonIcon from '@/components/base/button/BaseButtonIcon.vue';
import BaseFlyOut from '@/components/base/BaseFlyOut.vue';
import BaseLoader from '@/components/base/BaseLoader.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutSection from '@/layouts/LayoutSection.vue';
import Vue, { VueConstructor } from 'vue';
import { CONTEXT_NAME } from '@/utils/contextName';
import { EventBusEvent } from '@/utils/eventBus';
import { IAction } from '@/typings/interface/IAction';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'LayoutPage',

  components: {
    BaseButtonIcon,
    BaseFlyOut,
    BaseLoader,
    BreadCrumbs,
    LayoutHeader,
    LayoutSection,
  },

  props: {
    actionList: {
      type: Array as () => Array<IAction>,
      default: function() {
        return [
          {
            event: 'remove',
            icon: 'trashcan',
            label: 'common.action.delete',
            name: 'remove',
          },
        ];
      },
    },

    expanded: {
      type: Boolean,
      default: false,
    },

    hasActions: {
      type: Boolean,
      default: false,
    },

    heading: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      CONTEXT_NAME,
    };
  },

  computed: {
    contentClassList() {
      return {
        'page__content--expanded': this.expanded,
      };
    },
  },
});
